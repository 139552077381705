import React, { useContext } from 'react';

import { LanguageContext } from 'components/Layout';

import useAboutData from 'queries/AboutQueries';
import styles from './about.module.scss';

const About = () => {
  const { aboutSections } = useAboutData();
  const { language } = useContext(LanguageContext);
  return (
    <div className={styles.aboutContainer}>
      {
        aboutSections.map((section) => (
          <div key={section.strapiId} className={styles.aboutSection}>
            <h2>{section[`title_${language}`]}</h2>
            <p>{section[`text_${language}`]}</p>
          </div>
        ))
      }
    </div>
  );
};

export default About;
