import React, { useState, useRef, useContext } from 'react';
import HorizontalTimeline from 'react-styled-horizontal-timeline';
import Slider from 'react-slick';
import { IoIosArrowDropright, IoIosArrowDropleft } from 'react-icons/io';
import BackgroundImage from 'gatsby-background-image';

import { LanguageContext } from 'components/Layout';

import useAboutData from 'queries/AboutQueries';
import useWindowWidth from 'utilities/windowWidth';

import styles from './about.module.scss';

const Timeline = () => {
  const [windowWidth, setWindowWidth] = useState(0);
  const sliderRef = useRef(null);
  const { language } = useContext(LanguageContext);

  useWindowWidth({ setWindowWidth });

  const { timelineYears, timelineDescriptions, backgroundImage } = useAboutData();

  const [yearIndex, setYearIndex] = useState({ value: 0, previous: 0 });
  const goNext = () => {
    if (sliderRef.current) {
      sliderRef.current.slickNext();
    }
  };

  const goBack = () => {
    if (sliderRef.current) {
      sliderRef.current.slickPrev();
    }
  };

  const mobileSettings = {
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  return (
    <BackgroundImage
      fluid={backgroundImage}
      className={styles.backgroundImage}
    >
      {
        windowWidth < 480 ? (
          <div className={styles.timelineSliderContainer}>
            <IoIosArrowDropleft className={styles.prevArrow} onClick={goBack} />
            <hr className={styles.divider} />
            <Slider
              {...mobileSettings}
              className={styles.timelineSlider}
              ref={(n) => (sliderRef.current = n)}
            >
              {
              timelineYears.map((year, index) => (
                <div key={year} className={styles.timelineSlide}>
                  <h2>{year}</h2>
                  <h3>{timelineDescriptions[`description_${language}`][index]}</h3>
                </div>
              ))
            }
            </Slider>
            <IoIosArrowDropright className={styles.nextArrow} onClick={goNext} />
          </div>
        ) : (
          <div className={styles.timelineContainer}>
            <div className={styles.timeline}>
              <HorizontalTimeline
                values={timelineYears}
                index={yearIndex.value}
                indexClick={(index) => {
                  setYearIndex({ value: index, previous: yearIndex.value });
                }}
                styles={{
                  background: '#085acc', foreground: 'white', outline: 'rgba(0,0,0,0.4)', label: 'white',
                }}
                minEventPadding={40}
                labelWidth={120}
                getLabel={(date) => date.slice(0, 4)}
              />
            </div>
            <div className={styles.timelineContent}>
              <h2>{timelineDescriptions[`description_${language}`][yearIndex.value]}</h2>
              <h3>{timelineYears[yearIndex.value]}</h3>
            </div>
          </div>
        )
      }
    </BackgroundImage>
  );
};

export default Timeline;
