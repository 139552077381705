import React from 'react';

import About from 'components/About/About';
import Contact from 'components/About/Contact';
import Timeline from 'components/About/Timeline';
import Layout from 'components/Layout';
import ScrollTopArrow from 'utilities/scrollToTop';

import aboutPic from 'images/about.jpg';

const IndexPage = () => (
  <Layout
    title="O nama"
    description="Ljubav prema detalju je smjernica kojom se vodimo prilikom obrade svih zahtjeva. Primjenom inovativnih principa rada omogućujemo konkurentnost naših usluga. U GAT kompaniji se koristi oprema koja garantuje preciznost i visoku produktivnost. Visoko integrisanim informatičkim sistemom obezbjeđujemo potpunu sljedivost u svim proizvodnim procesima."
    image={aboutPic}
  >
    <ScrollTopArrow />
    <Timeline />
    <About />
    <Contact />
  </Layout>
);

export default IndexPage;
